//  Modules from the React eco-system
import { useState, useEffect } from "react"
import Loader from "../components/loader.component"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

import { Column } from "react-table"
import { truncate } from "lodash"
import TableDisplayer from "../components/table.component"
import tasks from "../helper/tasks.json";

import default_avatar from "../assets/img/default-avatar.jpg"
import { Badge, Col, Row } from "react-bootstrap"
import { statusBadgeType } from "../helper/_helper"
import { mdiFilePdfBox, mdiImage } from "@mdi/js"
import Icon from "@mdi/react"
import { columns } from "../helper/columns"

function Tasks() {
    const dispatch = useDispatch()

    const [data, setData] = useState<any>(tasks.data);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Feladatok", pageUrl: "/admin/tasks", subPageName: "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const columns: Column<any>[] = [
    //     {
    //         Header: "Név",
    //         accessor: "name",
    //         Cell: (data: any) => {
    //             return (
    //                 <div>
    //                     <p>
    //                         {data.value}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Leírás",
    //         accessor: "description",
    //         Cell: (data: any, index:number) => {
    //             return (
    //                 <div className="table-long-text">
    //                     <p className="truncated-text">
    //                         {truncate(data.value, {length: 40})}
    //                     </p>
    //                     <p className="popup-text">
    //                         {data.value}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Spot",
    //         accessor: "spot",
    //         Cell: (data: any, index:number) => {
    //             return (
    //                 <div>
    //                     <p>
    //                         {data.value.name}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Felelős",
    //         accessor: "user",
    //         Cell: (data: any, index:number) => {
    //             return (
    //                 <div className="table-user">
    //                     <div className="avatar">
    //                             <img className="user-picture" alt="profile-avatar" src={data.value.image ? data.value.image : default_avatar} />
    //                     </div>
    //                     <p>
    //                         {`${data.value.firstname} ${data.value.lastname}`}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Határidő",
    //         accessor: "date",
    //         Cell: (data: any, index:number) => {
    //             return (
    //                 <div>
    //                     <p>
    //                         {data.value}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Költség becslés",
    //         accessor: "cost",
    //         Cell: (data: any) => {
    //             const index = data.row.index;
    //             return (
    //                 <div>
    //                     <p>
    //                         {`${new Intl.NumberFormat("hu-HU").format(data.value)} ${data.data[index].currency}`}
    //                     </p>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    //     {
    //         Header: "Státusz",
    //         accessor: "status",
    //         Cell: (data: any, index:number) => {
    //             return (
    //                 <div>
    //                     <Badge className="table-status-badge" bg={(statusBadgeType as any)[data.value]}>
    //                         {data.value}
    //                     </Badge>
    //                 </div>
    //             )
    //         },
    //         disableFilters: true,
    //     },
    // ]

    const rowSubComponent = (data:any) => {
        const values = data.row.values;
        return (
            <Row className="table-expanded-component">
                <Col sm={1}><a href={`${process.env.REACT_APP_API_URL}/public/1.pdf`}><Icon path={mdiFilePdfBox} size={2} /></a></Col>
                <Col sm={1}><a href={`${process.env.REACT_APP_API_URL}/public/A.0.APP.04.01.K.V14.pdf`}><Icon path={mdiImage} size={2} /></a></Col>
                <Col sm={10}><p>{values.description}</p></Col>
            </Row>
        )
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="table-tile centered-text-table">
                        <TableDisplayer data={data} columns={columns} renderRowSubComponent={rowSubComponent} setData={setData} isDraggable={true} />
                    </div>
                </>
            )}
        </div>
    )
}

export default Tasks
