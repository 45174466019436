//  Modules from the React eco-system
import React from "react"
import { useEffect } from 'react'
import { Link } from "react-router-dom"
import 'leaflet/dist/leaflet.css';
import "react-leaflet-fullscreen/styles.css";
import L from 'leaflet'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import { FullscreenControl } from "react-leaflet-fullscreen";

// Own components
import RegionInfo from "./region-info.component"
import CustomGoogleMap from "../google-map.component"
import utemterv from "../../assets/img/utemterv.png"

// Interfaces, enums
import { IRegion, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    region: IRegion
}
/**
 * region details page, details component
 * @param props
 * @returns
 */
function RegionHome(props: IProps) {
    useEffect(() => {
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: iconRetinaUrl,
            iconUrl: iconUrl,
            shadowUrl: shadowUrl,
        })
    }, [])

    const markerData = (props.region.cubes as ICube[]).map((cube) => {
        let info;

        return (
            <Marker key={cube._id} position={cube.location.coordinates as any}>
                <Popup>
                    <div className="infoWindow mt-1">
                        <Link to={`/admin/cubes/${cube._id}`}><b>{cube.name}</b></Link>
                        <Row><span><b>Feladat:</b> Takarítás</span></Row>
                        <Row><span><b>Feladat:</b> Javítás</span></Row>
                    </div>
                </Popup>
            </Marker>
        )
    })


    const markerBounds = (props.region.cubes as ICube[]).map((cube) => cube.location.coordinates)

    return (
        <div className="region-details">
            <Row>
                <Col xs={12} md={4}>
                    <RegionInfo region={props.region} />
                </Col>

                <Col xs={12} md={8}>
                    <div className="simple-tile map-tile">
                        <MapContainer
                            style={{ height: '100vh', width: '100wh' }}
                            center={props.region.location?.coordinates as any}
                            bounds={markerBounds as L.LatLngBoundsExpression}
                            >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                minZoom={17}
                                maxZoom={21}
                                //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                url="https://api.zalazone.spotmonitor.hu/public/tiles/{z}/{x}/{y}.png"
                            />
                            <FullscreenControl />
                            {markerData}
                        </MapContainer>
                    </div>
                </Col>
            </Row>
            {props.region.description ? (
                <Row>
                    <Col>
                        <div className="tile">
                            <p>{props.region.description}</p>
                            <p><img style={{width: '100%'}} src={utemterv} /></p>
                        </div>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </div>
    )
}

export default RegionHome
