//  Modules from the React eco-system
import React from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import CubeForm from "../forms/cube.form"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

const FormInitialValues = {
    name: "",
    region: "",
    description: "",
    shortDescription: "",
    side: "",
    threshold: 0,
    location: [47.18037912854737, 19.50450897216797],
    mqtt_name: "",
    home_assistant_ip: "",
    type: "",
    group: [],
}

function CreateCube() {
    const history = useHistory()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: FormInitialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
            region: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            shortDescription: Yup.string().required("Required"),
            //side: Yup.string().required("Required"),
            threshold: Yup.number().required("Required"),
            location: Yup.array().min(2).max(2).required("Required"),
            mqtt_name: Yup.string().required("Required"),
            home_assistant_ip: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
            group: Yup.array().required("Required"),
        }),
        onSubmit: async (values, actions) => {
            try {
                const cubeData = {
                    name: values.name,
                    region: values.region,
                    description: values.description,
                    short_description: values.shortDescription,
                    side: values.side,
                    threshold: values.threshold,
                    location: {
                        type: "Point",
                        coordinates: values.location,
                    },
                    mqtt_name: values.mqtt_name,
                    home_assistant_ip: values.home_assistant_ip,
                    type: values.type,
                    group: values.group,
                }
                const response = await axiosClient.post("/spot", cubeData)
                toast.success("The Spot is created successfully")
                history.push(`/admin/cubes/${response.data._id}`)

                actions.resetForm({ values: FormInitialValues })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div>
            <CubeForm formik={formik} />
        </div>
    )
}

export default CreateCube
