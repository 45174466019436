import { truncate } from "lodash"
import { Badge } from "react-bootstrap"
import { statusBadgeType } from "./_helper"

import default_avatar from "../assets/img/default-avatar.jpg";

export const columns = [
    {
        Header: "Feladat felelőse",
        accessor: "user",
        Cell: (data: any) => {
            return (
                <div className="table-user">
                    <div className="avatar">
                        <img className="user-picture" alt="profile-avatar" src={data.value.image ? data.value.image : default_avatar} />
                    </div>
                    <p>
                        {`${data.value.firstname} ${data.value.lastname}`}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Modul kódja",
        accessor: "module_code",
        Cell: (data: any, index: number) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Modul",
        accessor: "module",
        Cell: (data: any, index: number) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Felület megnevezése",
        accessor: "env_name",
        Cell: (data: any, index: number) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Rendszerelem megnevezése",
        accessor: "element_name",
        Cell: (data: any, index: number) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Rendszerelem részeleme",
        accessor: "part_element",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Ellenőrzés módja",
        accessor: "checking_mode",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Műszaki hiba jellege",
        accessor: "error_type",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Beavatkozás típusa",
        accessor: "interference_type",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Anyag- Erőforrás szükséglet",
        accessor: "material_resource",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Beavatkozást végzők státusza",
        accessor: "interferencer_status",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Ellenőrzés javasolt gyakorisága",
        accessor: "checking_frequency",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Ellenőrzés időpontja",
        accessor: "checking_date",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Ellenőrzést végezte",
        accessor: "checking_user",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Beavatkozás időpontja",
        accessor: "interference_date",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Beavatkozás megfelelése",
        accessor: "interference_acceptance",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Ellenőrzés típusa",
        accessor: "checking_type",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Telepítés, beüzemelés időpontja",
        accessor: "installation_date",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Tervezett élettartam (amennyiben meghatározható)",
        accessor: "planned_lifetime",
        Cell: (data: any) => {
            return (
                <div>
                    <p>
                        {data.value}
                    </p>
                </div>
            )
        },
        disableFilters: true,
        isHidden: true,
    },
    {
        Header: "Becsült költség",
        accessor: "cost",
        Cell: (data: any) => {
            const index = data.row.index;
            return (
                <div>
                    <p>
                        {`${new Intl.NumberFormat("hu-HU").format(data.value)} ${data.data[index].currency}`}
                    </p>
                </div>
            )
        },
        disableFilters: true,
    },
    {
        Header: "Státusz",
        accessor: "status",
        Cell: (data: any, index: number) => {
            return (
                <div>
                    <Badge className="table-status-badge" bg={(statusBadgeType as any)[data.value]}>
                        {data.value}
                    </Badge>
                </div>
            )
        },
        disableFilters: true,
    },
]