//  Modules from the React eco-system
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"

import { ToastContainer, toast, Flip } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// pages
import AdminLayout from "./layout/admin.layout"
import VerificationPage from "./pages/verification.page"
import Login from "./pages/login.page"

// redux store
import { RootState } from "./store/store"
import PasswordResetPage from "./pages/password-reset.page"
import ForgotPassword from "./pages/forgot-password.page"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

function App() {
    // authentication state from the redux
    const { isAuthenticated } = useSelector((state) => (state as RootState).userReducer)

    return (
        <>
            <DndProvider backend={HTML5Backend}>
            <Router>
                <Switch>
                    <Route path="/verify/:token">{<VerificationPage />}</Route>
                    {/* mobile */}
                    <Route path="/password-reset/:token">{<PasswordResetPage />}</Route>
                    {/* admin */}
                    <Route path="/forgot-password/:token">{<ForgotPassword />}</Route>
                    <Route path="/login">{isAuthenticated ? <Redirect to="/admin" /> : <Login />}</Route>
                    <Route path="/admin">{isAuthenticated ? <AdminLayout /> : <Redirect to="/login" />}</Route>
                    <Redirect to={isAuthenticated ? "/admin" : "/login"} />
                </Switch>
            </Router>
            <ToastContainer transition={Flip} limit={5} theme="colored" position={toast.POSITION.TOP_CENTER} autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick pauseOnHover />
            </DndProvider>
        </>
    )
}

export default App
