export enum EStatus {
    APPROVED = "Elfogadva",
    IN_PROGRESS = "Folyamatban",
    SCHEDULED = "Ütemezett",
}

export const statusBadgeType = {
    "Elfogadva": "success",
    "Folyamatban": "warning",
    "Ütemezett": "primary"
}