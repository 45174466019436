import React, { useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useTable, useSortBy, useFilters, usePagination, useExpanded } from "react-table"
import { mdiSortBoolAscending, mdiDotsVertical, mdiSortBoolDescending, mdiSwapVertical, mdiChevronLeft, mdiChevronRight, mdiSkipForward, mdiSkipBackward, mdiMenuDown, mdiMenuUp, mdiDrag } from "@mdi/js"
import Icon from "@mdi/react"
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'

//TODO: itt kicsit sok az any nem ? 
// FIXME: egyelőre nem sikerült a type-okat rendbe tennem itt
interface IProps {
    columns: any
    data: any
    setData?: Function
    renderRowSubComponent?: Function // function to render the subcomponent (displayed if the row is collapsed)
    paginationOptions?: any
    isDraggable?: boolean
}

export default function TableDisplayer({ columns, data, renderRowSubComponent, paginationOptions, setData, isDraggable }: IProps) {
    const [showOptions, setShowOptions] = useState(false)

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        visibleColumns,
        page,
        nextPage,
        previousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        setPageSize,
        state,
        canPreviousPage,
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        allColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: (paginationOptions && paginationOptions.pageSize) || 10,
                hiddenColumns: columns.filter((column: any) => column?.isHidden).map((column: any) => column.accessor),
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const moveRow = (dragIndex:any, hoverIndex:any) => {
        const dragRecord = data[dragIndex]
        if (setData) {
            setData(
            update(data, {
                $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
                ],
            })
            )
        }
      }

    const { pageIndex, pageSize } = state

    return (
        <div className="table">
            <div className="table-actions">
                <div className="show-hide-columns" onClick={() => setShowOptions(!showOptions)}>
                    <Icon path={mdiDotsVertical} size={3} />
                </div>
                {showOptions && (
                    <div className="options">
                        <div className="col-selectors">
                            {allColumns.map((column) => (
                                <div className="selector-item" key={column.id}>
                                    <input className="checkbox" type="checkbox" {...column.getToggleHiddenProps()} />
                                    <label>{column.Header}</label>
                                </div>
                            ))}
                        </div>
                        <div>
                            <select className="pagination-selector" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[10, 25, 50].map((pageSize) => {
                                    return (
                                        <option value={pageSize} key={pageSize}>
                                            {`Show ${pageSize}`}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                )}
            </div>
            <Table {...getTableProps()} borderless responsive>
                <thead className="filter-header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <div className="filter">{column.canFilter ? column.render("Filter") : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <thead className="header-title">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {renderRowSubComponent && <th></th>}
                            {isDraggable && <th></th>}
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <span className="title">{column.render("Header")}</span>

                                    <span {...column.getSortByToggleProps()}>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon path={mdiSortBoolAscending} size={0.8} />
                                            ) : (
                                                <Icon path={mdiSortBoolDescending} size={0.8} />
                                            )
                                        ) : column.canSort ? (
                                            <Icon path={mdiSwapVertical} size={0.8} />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className={!page.length ? "empty-table" : ""} {...getTableBodyProps()}>
                    {!page.length && <p className="table-empty-state-message">There are no items in this list. Please create a new item!</p>}
                    {page && page.length && page.map((row, index) => {
                        prepareRow(row)
                        return (
                            <Row
                                index={index}
                                row={row}
                                moveRow={moveRow}
                                {...row.getRowProps()}
                                renderRowSubComponent={renderRowSubComponent}
                                visibleColumns={visibleColumns}
                                isDraggable={isDraggable}
                            />
                        )
                    })}
                </tbody>
            </Table>
            <div className="pagination">
                <div>
                    <span onClick={() => canPreviousPage && gotoPage(0)} className={!canPreviousPage ? "disabled" : ""}>
                        <Icon path={mdiSkipBackward} size={1} />
                    </span>
                    <span onClick={() => canPreviousPage && previousPage()} className={!canPreviousPage ? "disabled" : ""}>
                        <Icon path={mdiChevronLeft} size={1} />
                    </span>
                    <span>{` ${pageIndex + 1} / ${pageOptions.length} `}</span>
                    <span onClick={() => canNextPage && nextPage()} className={!canNextPage ? "disabled" : ""}>
                        <Icon path={mdiChevronRight} size={1} />
                    </span>
                    <span onClick={() => canNextPage && gotoPage(pageOptions.length-1)} className={!canNextPage ? "disabled" : ""}>
                        <Icon path={mdiSkipForward} size={1} />
                    </span>
                </div>
            </div>
        </div>
    )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow, renderRowSubComponent, visibleColumns, isDraggable }:{row:any, index:any, moveRow:any, renderRowSubComponent:any, visibleColumns:any, isDraggable:any}) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)
  
    const [, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return
        }
        const dragIndex = (item as any).index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = (dropRef.current as any).getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        //@ts-ignore
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        (item as any).index = hoverIndex
      },
    })
  
    const [{ isDragging }, drag, preview] = useDrag({
      item: { type: DND_ITEM_TYPE, index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      type: DND_ITEM_TYPE
    })
  
    const opacity = isDragging ? 0 : 1
  
    preview(drop(dropRef))
    drag(dragRef)
  
    return (
        <>
      <tr ref={dropRef} style={{ opacity }}>
        {isDraggable && <td ref={dragRef}><Icon path={mdiDrag} size={1}/></td>}
            {renderRowSubComponent && <td className="row-expanded-cell"><Button onClick={() => row.toggleRowExpanded()}><Icon path={row.isExpanded ? mdiMenuUp : mdiMenuDown} /></Button></td>}
                {row.cells.map((cell:any) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
            {/* render subcomponent if the row is expanded */}
      </tr>
      {row.isExpanded && renderRowSubComponent ? (
        <tr>
            <td colSpan={visibleColumns.length + (isDraggable ? 2 : 1)}>{renderRowSubComponent({ row })}</td>
        </tr>
        ) : null}
      </>
    )
  }
