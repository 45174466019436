//  Modules from the React eco-system
import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { Column } from "react-table"

// Own components
import { dateFilter, selectColumnFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"
import CustomGoogleMap from "../google-map.component"

// Interfaces, enums
import { ESpotGroup, ESpotType, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import {mdiTune, mdiCubeOutline, mdiWarehouse, mdiHomeVariant, mdiFilterMenu} from "@mdi/js"
import { Row, Col, Button, ButtonGroup, Form } from "react-bootstrap"
import moment from "moment"
import { useDispatch } from "react-redux";
import { toggleIsSidebarNarrow } from "../../store/pageSlice";

interface IProps {
    cubes: ICube[]
    hiddenColumns?: string[]
}

function CubesTable(props: IProps) {
    // display modes
    const [showTable, setShowTable] = useState<boolean>(true)
    const [showMap, setShowMap] = useState<boolean>(true)
    const [showFilters, setShowFilters] = useState<boolean>(false);

    //Markers
    const [allMarkers, setAllMarkers] = useState<any>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<any>([]);

    //Filters
    const [typeFilters, setTypeFilters] = useState<string[]>([]);
    const [groupFilters, setGroupFilters] = useState<string[]>([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        getMarkerData();
    }, [])

    const columns: Column<any>[] = useMemo(
        () => [
            {
                Header: t("Details"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/cubes/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Image"),
                accessor: "images",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value[0]} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiHomeVariant} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: t("Name"),
                accessor: "name",
                Cell: (data) => {
                    return <Link to={`/admin/cubes/${data.row.original._id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: "Típus",
                accessor: "type",
                Cell: (data) => {
                    return <span>{data.value || "-"}</span>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("type"),
            },

            {
                Header: "Id",
                accessor: "_id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: t("Created At"),
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
            {
                Header: t("Region"),
                accessor: "region.name",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("region"),
            },
            {
                Header: t("Short description"),
                accessor: "short_description",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("short_description"),
            },
        ],
        [props.hiddenColumns]
    )

    function getMarkerData() {
        const markerData = (props.cubes as ICube[]).map((cube) => {
            let info;
            if (cube._id === "653a502cca99c1ffa26695e3") {
                info = (
                    <div className="mt-1">
                        <Row><span><b>Feladat:</b> Takarítás</span></Row>
                        <Row><span><b>Feladat:</b> Javítás</span></Row>
                    </div>
                )
            }
            return {
                position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                infoWindow: (
                    <div className="infoWindow">
                        <Link to={`/admin/cubes/${cube._id}`}><b>{cube.name}</b></Link>
                        {info}
                    </div>
                ),
            }
        })
        setAllMarkers(markerData);
        setFilteredMarkers(markerData);
    }

    function onTypeFilterChanged(type:string) {
        let updatedTypeFilters = [...typeFilters];
        if (updatedTypeFilters.includes(type)) {
            const index = updatedTypeFilters.findIndex(filter => filter === type);
            updatedTypeFilters.splice(index, 1);
        } else {
            updatedTypeFilters.push(type);
        }
        setTypeFilters(updatedTypeFilters);

        if (updatedTypeFilters.length || groupFilters.length) {
            const updatedFilteredMarkers:any = [];
            (props.cubes as ICube[]).forEach((cube) => {
                const foundGroup = groupFilters.some(r => cube.group?.includes(r as ESpotGroup))
                let info;
                if (cube._id === "653a502cca99c1ffa26695e3") {
                    info = (
                        <div className="mt-1">
                            <Row><span><b>Feladat:</b> Takarítás</span></Row>
                            <Row><span><b>Feladat:</b> Javítás</span></Row>
                        </div>
                    )
                }
                if ((cube.type && updatedTypeFilters.includes(cube.type)) || foundGroup) { 
                    updatedFilteredMarkers.push({
                        position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                        infoWindow: (
                            <div className="infoWindow">
                                <Link to={`/admin/cubes/${cube._id}`}><b>{cube.name}</b></Link>
                                {info}
                            </div>
                        ),
                    })
                }
            })
            setFilteredMarkers(updatedFilteredMarkers);
        } else {
            setFilteredMarkers(allMarkers);
        }
    }

    function onGroupFilterChanged(group:string) {
        let updatedGroupFilters = [...groupFilters];
        if (updatedGroupFilters.includes(group)) {
            const index = updatedGroupFilters.findIndex(filter => filter === group);
            updatedGroupFilters.splice(index, 1);
        } else {
            updatedGroupFilters.push(group);
        }

        if (updatedGroupFilters.length || typeFilters.length) {
            const updatedFilteredMarkers:any = [];
            (props.cubes as ICube[]).forEach((cube) => {
                const foundGroup = updatedGroupFilters.some(r => cube.group?.includes(r as ESpotGroup))
                if (foundGroup || (cube.type && typeFilters.includes(cube.type))) { 
                    let info;
                    if (cube._id === "653a502cca99c1ffa26695e3") {
                        info = (
                            <div className="mt-1">
                                <Row><span><b>Feladat:</b> Takarítás</span></Row>
                                <Row><span><b>Feladat:</b> Javítás</span></Row>
                            </div>
                        )
                    }
                    updatedFilteredMarkers.push({
                        position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                        infoWindow: (
                            <div className="infoWindow">
                                <Link to={`/admin/cubes/${cube._id}`}><b>{cube.name}</b></Link>
                                {info}
                            </div>
                        ),
                    })
                }
            })
            setFilteredMarkers(updatedFilteredMarkers);
        } else {
            setFilteredMarkers(allMarkers);
        }

        setGroupFilters(updatedGroupFilters);
    }

    return (
        <div className="cubes-table">
            <Row className={(showMap && !showTable) ? "map-view" : ""}>
                <Col className="display-selector-container">

                    <Button className="btn orange filter-button" onClick={() => setShowFilters(!showFilters)}><Icon path={mdiFilterMenu} size={1} /></Button>

                </Col>
            </Row>
            <Row>
                {showTable ? (
                    <Col>
                        <div className="table-tile">{props.cubes && <TableDisplayer columns={columns} data={props.cubes} />}</div>
                    </Col>
                ) : (
                    <></>
                )}
                {showMap ? (
                    <Col>
                        <div className="simple-tile map-tile">
                            <CustomGoogleMap
                                zoom={parseInt(process.env.REACT_APP_SPOT_MAP_ZOOM_LEVEL || "12")}
                                center={
                                    props.cubes.length
                                        ? { lat: (props.cubes as ICube[])[0].location.coordinates[0], lng: (props.cubes as ICube[])[0].location.coordinates[1] }
                                        : { lat: 47.1803791, lng: 19.5045089 }
                                }
                                markerDatas={filteredMarkers}
                                minHeight={(showMap && !showTable ? "80vh" : "200px")}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
            {showFilters && (
                <Row className="p-3 filter-container">
                    <div >
                    <h5>Típus</h5>
                    {Object.keys(ESpotType).map((key) => {
                        return (
                            <Form.Group>
                                <Row>
                                    <Col sm={2}><Form.Check onChange={(e) => onTypeFilterChanged((ESpotType as any)[key])} checked={typeFilters.includes((ESpotType as any)[key])}/></Col>
                                    <Col sm={10}><Form.Label>{(ESpotType as any)[key]}</Form.Label></Col>
                                </Row>
                            </Form.Group>
                        )
                    })}
                    </div>
                    <div className="mt-5">
                    <h5>Csoport</h5>
                    {Object.keys(ESpotGroup).map((key) => {
                        return (
                            <Form.Group>
                                <Row>
                                    <Col sm={2}><Form.Check onChange={(e) => onGroupFilterChanged((ESpotGroup as any)[key])} checked={groupFilters.includes((ESpotGroup as any)[key])} /></Col>
                                    <Col sm={10}><Form.Label>{(ESpotGroup as any)[key]}</Form.Label></Col>
                                </Row>
                            </Form.Group>
                        )
                    })}
                    </div>
                </Row>
            )}
        </div>
    )
}

export default CubesTable
