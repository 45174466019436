import React, { useState, useEffect } from "react"
import { FormikProps } from "formik"
import { Form, Button, Row, Col } from "react-bootstrap"
import axiosClient from "../../api/api"
import { ESpotGroup, ESpotType, IRegion } from "../../interfaces/cubes"
import CreatableSelect from "react-select/creatable"
import CustomGoogleMap from "../google-map.component"
import Select from "react-select"

interface IProps {
    formik: FormikProps<any>
}

function CubeForm(props: IProps) {
    // regions from the regions fetch
    const [regions, setRegions] = useState<IRegion[]>([])

    useEffect(() => {
        // fetch regions
        fetchRegions()
    }, [])

    /**
     * fetch regions from the backend
     */
    async function fetchRegions() {
        try {
            const response = await axiosClient.get("/region?selectFields[]=name")
            setRegions(response.data)
        } catch (error) {
            console.log("Failed to fetch regions")
        }
    }

    function getMarkerData() {
        return [
            {
                position: { lat: props.formik.values.location[0], lng: props.formik.values.location[1] },
                draggable: true,
                onDragEnd: (lat: number, lng: number) => props.formik.setFieldValue("location", [lat, lng]),
            },
        ]
    }

    return (
        <Row>
            <Col>
                <div className="simple-tile">
                    {regions.length ? (
                        <Form onSubmit={props.formik.handleSubmit}>
                            <Row>
                                <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 0 }}>
                                    <div className="simple-tile map-tile">
                                        <CustomGoogleMap
                                            onClick={(lat: number, lng: number) => props.formik.setFieldValue("location", [lat, lng])}
                                            zoom={7}
                                            center={props.formik.values.location[0] ? { lat: props.formik.values.location[0], lng: props.formik.values.location[1] } : { lat: 47, lng: 17 }}
                                            markerDatas={getMarkerData()}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formCube">
                                        <Form.Label>Spot neve</Form.Label>
                                        <Form.Control
                                            onChange={props.formik.handleChange}
                                            name="name"
                                            onBlur={props.formik.handleBlur}
                                            value={props.formik.values.name}
                                            type="text"
                                            placeholder="Name..."
                                        />
                                        {props.formik.touched.name && props.formik.errors.name ? <p className="input-error">{props.formik.errors.name}</p> : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formCube">
                                        <Form.Label>Régió</Form.Label>
                                        <Form.Select onChange={props.formik.handleChange} name="region" value={props.formik.values.region} aria-label="Default select example">
                                            <option key="blankChoice" value="">
                                                Régió...
                                            </option>
                                            {regions.map((region) => {
                                                return (
                                                    <option key={region._id} value={region._id}>
                                                        {region.name}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                        {props.formik.touched.region && props.formik.errors.region ? <p className="input-error">{props.formik.errors.region}</p> : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formCube">
                                        <Form.Label>Rövid Leírás</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            onChange={props.formik.handleChange}
                                            name="shortDescription"
                                            onBlur={props.formik.handleBlur}
                                            value={props.formik.values.shortDescription}
                                            type="text"
                                            placeholder="Short description of the Spot"
                                        />
                                        {props.formik.touched.shortDescription && props.formik.errors.shortDescription ? <p className="input-error">{props.formik.errors.shortDescription}</p> : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formCube">
                                        <Form.Label>Hosszú Leírás</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={5}
                                            onChange={props.formik.handleChange}
                                            name="description"
                                            onBlur={props.formik.handleBlur}
                                            value={props.formik.values.description}
                                            type="text"
                                            placeholder="Description of the Spot"
                                        />
                                        {props.formik.touched.description && props.formik.errors.description ? <p className="input-error">{props.formik.errors.description}</p> : null}
                                    </Form.Group>
                                    <Row>
                                       {/* <Col>
                                             <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Side</Form.Label>
                                                <Form.Control
                                                    onChange={props.formik.handleChange}
                                                    name="side"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.side}
                                                    type="text"
                                                    placeholder="Side"
                                                />
                                                {props.formik.touched.side && props.formik.errors.side ? <p className="input-error">{props.formik.errors.side}</p> : null}
                                            </Form.Group>
                                        </Col> */}
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Küszöb érték</Form.Label>
                                                <Form.Control
                                                    onChange={props.formik.handleChange}
                                                    name="threshold"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.threshold}
                                                    type="text"
                                                    placeholder="Threshold"
                                                />
                                                {props.formik.touched.threshold && props.formik.errors.threshold ? <p className="input-error">{props.formik.errors.threshold}</p> : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Lat.: </Form.Label>
                                                <Form.Control
                                                    onChange={(e) => {
                                                        props.formik.setFieldValue("location", [Number(e.target.value), props.formik.values.location[1]])
                                                    }}
                                                    name="location"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.location[0] ? props.formik.values.location[0] : undefined}
                                                    type="number"
                                                    placeholder="Lat..."
                                                    step="0.000000000000001"
                                                    min="-90"
                                                    max="90"
                                                />
                                                {props.formik.touched.location && props.formik.errors.location ? <p className="input-error">{props.formik.errors.location}</p> : null}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Long.: </Form.Label>
                                                <Form.Control
                                                    onChange={(e) => {
                                                        props.formik.setFieldValue("location", [props.formik.values.location[0], Number(e.target.value)])
                                                    }}
                                                    name="long"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.location[1] ? props.formik.values.location[1] : undefined}
                                                    type="number"
                                                    placeholder="Long..."
                                                    step="0.000000000000001"
                                                    min="-180"
                                                    max="180"
                                                />
                                                {props.formik.touched.location && props.formik.errors.location ? <p className="input-error">{props.formik.errors.location}</p> : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>MQTT név</Form.Label>
                                                <Form.Control
                                                    onChange={props.formik.handleChange}
                                                    name="mqtt_name"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.mqtt_name}
                                                    type="text"
                                                    placeholder="MQTT name..."
                                                />
                                                {props.formik.touched.mqtt_name && props.formik.errors.mqtt_name ? <p className="input-error">{props.formik.errors.mqtt_name}</p> : null}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Home Assistant IP</Form.Label>
                                                <Form.Control
                                                    onChange={props.formik.handleChange}
                                                    name="home_assistant_ip"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.home_assistant_ip}
                                                    type="text"
                                                    placeholder="Home Assistant IP..."
                                                />
                                                {props.formik.touched.home_assistant_ip && props.formik.errors.home_assistant_ip ? (
                                                    <p className="input-error">{props.formik.errors.home_assistant_ip}</p>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Típus</Form.Label>
                                                <Form.Select
                                                    onChange={props.formik.handleChange}
                                                    name="type"
                                                    onBlur={props.formik.handleBlur}
                                                    value={props.formik.values.type}
                                                    placeholder="Type"
                                                >
                                                    <option value="">-</option>
                                                    {Object.keys(ESpotType).map((key) => {
                                                        return <option value={(ESpotType as any)[key]}>{(ESpotType as any)[key]}</option>;
                                                    })}
                                                </Form.Select>
                                                {props.formik.touched.side && props.formik.errors.side ? <p className="input-error">{props.formik.errors.side}</p> : null}
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formCube">
                                                <Form.Label>Csoport</Form.Label>
                                                <Select isMulti
                                                        options={Object.keys(ESpotGroup).map((key) => {
                                                            return { value: (ESpotGroup as any)[key], label: (ESpotGroup as any)[key] }
                                                        })}
                                                        onChange={(t) => props.formik.setFieldValue("group", [...t.map((item:any) => item.value)])}
                                                        value={props.formik.values.group?.map((item:string) => {
                                                            return { value: item, label: item }
                                                        })}
                                                        name="group"
                                                        maxMenuHeight={120}
                                                />
                                                {props.formik.touched.group && props.formik.errors.group ? <p className="input-error">{props.formik.errors.group}</p> : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <Button className="orange" type="submit">
                                                Kész
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <p>To create a cube, first create a region.</p>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default CubeForm
