import React, { useState, useEffect } from "react"
import { GoogleMap, LoadScript, StandaloneSearchBox } from "@react-google-maps/api"
import GoogleMapMarker from "./google-map-marker.component"
import { IMarkerData, IPosition } from "../interfaces/pages"

const containerStyle = {
    width: "100%",
    height: "100%",
    minWidth: "200px",
}

const defaultCenter = {
    lat: 46.895065,
    lng: 16.843546,
}

interface IProps {
    center?: IPosition
    zoom?: number
    onClick?: Function
    markerDatas?: IMarkerData[]
    minHeight?: string
}

function CustomGoogleMap(props: IProps) {
    const [searchBox, setSearchBox] = useState<any>(null)
    const [filterList, setFilterList] = useState<any>(null)

    const [centerOfMap, setcenterOfMap] = useState<IPosition>()
    const [map, setMap] = React.useState<any>(null)

    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY

    useEffect(() => {
        // if (props.center) {
        //     setcenterOfMap(props.center)
        // } else {
            setcenterOfMap(defaultCenter)
        //}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleOnClick(e: google.maps.MapMouseEvent) {
        if (props.onClick) {
            const lat = e.latLng?.lat()
            const lng = e.latLng?.lng()

            if (lat && lng) {
                props.onClick(lat, lng)
            }
        }
    }

    const onLoad = (ref: any) => {
        setSearchBox(ref)
    }

    const onFilterListLoad = (ref: any) => {
        setFilterList(ref)
    }

    const onPlacesChanged = () => setcenterOfMap({ lat: searchBox.getPlaces()[0].geometry.location.lat(), lng: searchBox.getPlaces()[0].geometry.location.lng() })
    return (
        <LoadScript googleMapsApiKey={apiKey || ""} libraries={["places"]}>
            <GoogleMap 
                mapContainerClassName="map"
                onClick={(e) => handleOnClick(e)}
                mapContainerStyle={{...containerStyle, minHeight: props.minHeight || "200px"}}
                center={centerOfMap}
                zoom={16}
                onLoad={onLoad}
                options={{streetViewControl: false, mapTypeId: "satellite"}}
            >
                {/* <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <input type="text" placeholder="Search..." className="searchbox" />
                </StandaloneSearchBox> */}

                {props.markerDatas?.map((data, index) => {
                    return <GoogleMapMarker key={index} markerData={data} />
                })}
            </GoogleMap>
        </LoadScript>
    )
}

export default React.memo(CustomGoogleMap)
