//  Modules from the React eco-system
import React from "react"

// Screens
import Cubes from "../pages/cubes.page"
import Dashboard from "../pages/dashboard.page"
import Regions from "../pages/regions.page"
import Users from "../pages/users.page"
import RegionDetails from "../pages/region-details.page"
import CubeDetails from "../pages/cube-details.page"
import Suppliers from "../pages/suppliers.page"
import SupplierDetails from "../pages/supplier-details.page"
import Events from "../pages/events.page"
import EventDetails from "../pages/event-details.page"
import UserDetails from "../pages/user-details.page"
import Operators from "../pages/operators.page"
import OperatorDetails from "../pages/operators-details.page"
import SuperadminPage from "../pages/superadmins.page"
import LoggedInUserPage from "../pages/profile.page"

// Interfaces, enums
import {ERoles} from "../constants/enum"

// Styles, bootstrap, icons
import {
    mdiAccountMultipleOutline,
    mdiAccountStarOutline,
    mdiCalendarBlankOutline,
    mdiChartDonut,
    mdiCubeOutline,
    mdiMapLegend,
    mdiTableCheck,
    mdiTruckDeliveryOutline,
    mdiWrenchOutline,
} from "@mdi/js"
import NotFoundPage from "../pages/not-found.page"
import RegionAdmins from "../pages/regionadmins.page";
import RegionAdminDetails from "../pages/regionadmins-details.page";
import Organizers from "../pages/organizers.page"
import OrganizerDetails from "../pages/organizers-details.page"
import Tasks from "../pages/tasks.page"
import Surveys from "../pages/surveys.page"

export interface IDashboardRoute {
    path: string
    name: string
    exact: boolean
    icon: string // for the sidebar
    component: React.FunctionComponent
    displayOnSidebar: boolean // if false: it does not displayed on the sidebar
    permission: ERoles[] // for the filtering by the logged in user
}

const dashboardRoutes: IDashboardRoute[] = [
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        exact: true,
        icon: mdiChartDonut,
        component: Dashboard,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER, ERoles.ORGANIZER],
    },
    {
        path: "/admin/regions/main/:page?",
        name: "Regions",
        exact: true,
        icon: mdiMapLegend,
        component: Regions,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER],
    },
    {
        path: "/admin/regions/:id/:page?",
        name: "Regions",
        exact: true,
        icon: mdiMapLegend,
        component: RegionDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER],
    },
    {
        path: "/admin/cubes/main/:page?",
        name: "Spots",
        exact: true,
        icon: mdiCubeOutline,
        component: Cubes,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER, ERoles.ORGANIZER],
    },
    {
        path: "/admin/cubes/:id/:page?",
        name: "Spots",
        exact: true,
        icon: mdiCubeOutline,
        component: CubeDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER, ERoles.ORGANIZER],
    },
    {
        path: "/admin/events",
        name: "Events",
        exact: true,
        icon: mdiCalendarBlankOutline,
        component: Events,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN],
    },
    {
        path: "/admin/event/:id",
        name: "Events",
        exact: true,
        icon: mdiCalendarBlankOutline,
        component: EventDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.ORGANIZER],
    },
    {
        path: "/admin/regionadmins/main/:page?",
        name: "Region Admins",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: RegionAdmins,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/regionadmins/:id/:page?",
        name: "Region Admins",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: RegionAdminDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/organizers/main/:page?",
        name: "Organizers",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: Organizers,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/organizer/:id/:page?",
        name: "Organizers",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: OrganizerDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/operators/main/:page?",
        name: "Operators",
        exact: true,
        icon: mdiWrenchOutline,
        component: Operators,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.REGIONADMIN],
    },
    {
        path: "/admin/operators/:id/:page?",
        name: "Operators",
        exact: true,
        icon: mdiWrenchOutline,
        component: OperatorDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.REGIONADMIN],
    },
    {
        path: "/admin/suppliers/main/:page?",
        name: "Suppliers",
        exact: true,
        icon: mdiTruckDeliveryOutline,
        component: Suppliers,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.ORGANIZER],
    },
    {
        path: "/admin/suppliers/:id/:page?",
        name: "Suppliers",
        exact: true,
        icon: mdiTruckDeliveryOutline,
        component: SupplierDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.ORGANIZER],
    },
    {
        path: "/admin/users/main/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: Users,
        displayOnSidebar: true,
        permission: [],
    },
    {
        path: "/admin/users/:id/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: UserDetails,
        displayOnSidebar: false,
        permission: [],
    },
    {
        path: "/admin/superadmins/:page?",
        name: "Administrators",
        exact: true,
        icon: mdiAccountStarOutline,
        component: SuperadminPage,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/profile",
        name: "Own profile",
        exact: true,
        icon: mdiAccountStarOutline,
        component: LoggedInUserPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER],
    },
    {
        path: "/admin/404",
        name: "Not Found",
        exact: true,
        icon: mdiAccountStarOutline,
        component: NotFoundPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER],
    },
    {
        path: "/admin/tasks",
        name: "Feladatok",
        exact: true,
        icon: mdiTableCheck,
        component: Tasks,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.REGIONADMIN],
    },
    {
        path: "/admin/surveys",
        name: "Felmérések",
        exact: true,
        icon: mdiTableCheck,
        component: Surveys,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.REGIONADMIN],
    },
]

export default dashboardRoutes
