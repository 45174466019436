//  Modules from the React eco-system
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import CubeImageUploader from "./cube-image-uploader-modal.component"
import CubeForm from "../forms/cube.form"

// Axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// Interfaces, enums
import { ICube } from "../../interfaces/cubes"

import Icon from "@mdi/react"
import { mdiCloseBoxOutline, mdiStarCircleOutline } from "@mdi/js"

// Styles, bootstrap, icons
import { Col, Row, Button, OverlayTrigger, Popover } from "react-bootstrap"

interface IProps {
    cube: ICube
    setCube: Function
}

/**
 * component for update or delete cube
 * @param props
 * @returns
 */
function UpdateCube(props: IProps) {
    const history = useHistory()

    // show image uploader
    const [showUploader, setShowUploader] = useState<boolean>(false)

    /**
     * popover for the delete button
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Spot törlése</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Biztosan törli ezt a Spotot?</p>
                    <Button className="btn orange" onClick={deleteCube}>
                        Törlés
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    /**
     * delete region from the backend
     */
    async function deleteCube() {
        try {
            if (props.cube && props.cube.events.length) {
                toast.error("Spot with event(s) can not be deleted")
                return
            }
            await axiosClient.delete(`/spot/${props.cube._id}`)
            toast.success("The Spot is deleted")
            history.push("/admin/cubes/main")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Spot delete failed")
            }
        }
    }

    /**
     * compare two arrays, return true if they are equal
     * @param array1
     * @param array2
     * @returns
     */
    function compareArrays(array1: string[], array2: string[]) {
        const array2Sorted = array2.slice().sort()
        const isTheSame: boolean =
            array1.length === array2.length &&
            array1
                .slice()
                .sort()
                .every(function (value, index) {
                    return value === array2Sorted[index]
                })

        return isTheSame
    }

    const formik = useFormik({
        initialValues: {
            name: props.cube.name,
            region: props.cube.region._id,
            description: props.cube.description,
            shortDescription: props.cube.short_description,
            side: props.cube.side,
            threshold: props.cube.threshold,
            location: [props.cube.location.coordinates[0], props.cube.location.coordinates[1]],
            mqtt_name: props.cube.mqtt_name,
            home_assistant_ip: props.cube.home_assistant_ip,
            type: props.cube.type || "",
            group: props.cube.group || "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
            region: Yup.string(),
            location: Yup.array(),
            //side: Yup.string().required("Required"),
            threshold: Yup.number().required("Required"),
            mqtt_name: Yup.string(),
            home_assistant_ip: Yup.string(),
            type: Yup.string().required("Required"),
            group: Yup.array().required("Required"),
        }),
        onSubmit: async (values, actions) => {
            try {
                if (props.cube) {
                    const cubeData = {
                        ...(values.mqtt_name && values.mqtt_name !== props.cube.mqtt_name && { mqtt_name: values.mqtt_name }),
                        ...(values.home_assistant_ip && values.home_assistant_ip !== props.cube.home_assistant_ip && { home_assistant_ip: values.home_assistant_ip }),
                        ...(values.name && values.name !== props.cube.name && { name: values.name }),
                        ...(values.side && values.side !== props.cube.side && { side: values.side }),
                        ...(values.threshold && values.threshold !== props.cube.threshold && { threshold: values.threshold }),
                        ...(values.region && values.region !== props.cube.region._id && { region: values.region }),
                        ...(values.description && values.description !== props.cube.description && { description: values.description }),
                        ...(values.shortDescription && values.shortDescription !== props.cube.short_description && { short_description: values.shortDescription }),
                        ...(values.type && values.type !== props.cube.type && { type: values.type }),
                        ...(values.group && values.group !== props.cube.group && { group: values.group }),
                        ...(values.location.length === 2 &&
                            values.location.join() !== props.cube.location.coordinates.join() && {
                                location: {
                                    type: "Point",
                                    coordinates: values.location,
                                },
                            }),
                    }

                    if (Object.keys(cubeData).length) {
                        const response = await axiosClient.patch(`/spot/${props.cube._id}`, cubeData)
                        props.setCube(response.data)
                        toast.success("The Spot is updated successfully")
                    }
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    /**
     * set image to higlighted
     */
    async function handleImageHighlight(highLightImage: string) {
        try {
            const newImageOrder = [highLightImage, ...props.cube.images.filter((image) => image !== highLightImage)]

            await axiosClient.patch(`/spot/${props.cube._id}`, { images: newImageOrder })

            props.setCube((prev: ICube) => {
                return { ...prev, images: newImageOrder }
            })
            toast.success("Item is set as the higlighted image of the Spot")
        } catch (error) {
            toast.error("Failed to update the item")
        }
    }

    /**
     * remove image from the cube
     */
    async function handleDeleteImage(removedImage: string) {
        try {
            await axiosClient.delete(`/spot/image/${props.cube._id}`, {
                data: {
                    image: removedImage,
                },
            })

            props.setCube((prev: ICube) => {
                return { ...prev, images: prev.images.filter((img) => img !== removedImage) }
            })
            toast.success("Image is removed")
        } catch (error) {
            toast.error("Failed to remove image")
        }
    }

    return (
        <>
            <CubeForm formik={formik} />

            <Row>
                <Col className="cube-image-grid mb-0">
                    {props.cube.images &&
                        props.cube.images.map((item, index) => (
                            <div key={`img-${index}`} className="cube-image-container">
                                <img src={item} alt="cube" className={index === 0 ? "first" : ""} />
                                <div className="cubeimage-actions-container">
                                    {index !== 0 ? (
                                        <div onClick={() => handleImageHighlight(item)}>
                                            <Icon className="cube-highlight" path={mdiStarCircleOutline} size={1} />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div onClick={() => handleDeleteImage(item)}>
                                        <Icon className="cube-remove" path={mdiCloseBoxOutline} size={1} />
                                    </div>
                                </div>
                            </div>
                        ))}
                </Col>
            </Row>
            <Row>
                <Col className="mt-1">
                    <Button className="btn orange btn-margin-fix" onClick={() => setShowUploader(true)}>
                        Kép feltöltés
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className="pt-3">
                    <div className="divider mb-4"></div>
                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                        <Button className="orange btn-margin-fix">Spot törlése</Button>
                    </OverlayTrigger>
                </Col>
            </Row>
            <CubeImageUploader show={showUploader} handleClose={() => setShowUploader(false)} cubeid={props.cube._id} setCube={props.setCube} />
        </>
    )
}

export default UpdateCube
